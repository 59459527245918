exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-programs-js": () => import("./../../../src/pages/affiliate-programs.js" /* webpackChunkName: "component---src-pages-affiliate-programs-js" */),
  "component---src-pages-appreciable-setup-js": () => import("./../../../src/pages/appreciableSetup.js" /* webpackChunkName: "component---src-pages-appreciable-setup-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-discount-mixer-faq-js": () => import("./../../../src/pages/discount-mixer-faq.js" /* webpackChunkName: "component---src-pages-discount-mixer-faq-js" */),
  "component---src-pages-discount-mixer-help-js": () => import("./../../../src/pages/discount-mixer-help.js" /* webpackChunkName: "component---src-pages-discount-mixer-help-js" */),
  "component---src-pages-discount-setup-js": () => import("./../../../src/pages/discountSetup.js" /* webpackChunkName: "component---src-pages-discount-setup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-center-js": () => import("./../../../src/pages/support-center.js" /* webpackChunkName: "component---src-pages-support-center-js" */),
  "component---src-pages-upsold-faq-js": () => import("./../../../src/pages/upsold-faq.js" /* webpackChunkName: "component---src-pages-upsold-faq-js" */),
  "component---src-pages-upsold-help-js": () => import("./../../../src/pages/upsold-help.js" /* webpackChunkName: "component---src-pages-upsold-help-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-support-post-js": () => import("./../../../src/templates/support-post.js" /* webpackChunkName: "component---src-templates-support-post-js" */)
}

